/**
 * This route is dynamically added to the app if this section is
 * enabled inside the config for the tenant being built.
 */
export function acpFraudDetectionStates($stateProvider: any) {
  'ngInject';

  $stateProvider.state('fraud-detection', {
    parent: 'public',
    url: '/fraud-detection',
    // Add resolve data as attributes: <acp-fraud-detection data="$resolve.fetchedData" ... />
    template: '<acp-fraud-detection />',
    data: {
      permissions: {
        only: 'password_unauthed'
      }
    },
    resolve: {
      module($ocLazyLoad) {
        'ngInject';

        return import(/* webpackChunkName: "acp.apps.fraud-detection" */ './index').then(
          $ocLazyLoad.loadModule
        );
      }
    }
  });
}
